.Games form {
	padding-bottom: 15px;
}

.Games form text {
	height: 30px;
	font-size: 24px;
}

.Games .game-score-label {
	font-size: larger;
	font-weight: bolder;
	text-align: center;
}
