.Home .lander {
	padding: 80px 0;
	text-align: center;
}

.Home .lander h1 {
	font-family: 'Orbitron', sans-serif;
	font-weight: 600;
}

.Home .lander p {
	color: #999;
}

.Home .games h4 {
	font-family: 'Orbitron', sans-serif;
	font-weight: 600;
	overflow: hidden;
	line-height: 1.5;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.Home .games p {
	color: #666;
}

.Home .games .games-list-header {
	font-size: larger;
	font-weight: bold;
}

.Home .games .games-list-player {
	text-align: center;
}

.Home .games .games-list-score {
	text-align: center;
	font-weight: bold;
}

.Home .games .games-list-high-score {
	color: white;
	background-color: cornflowerblue;
}
